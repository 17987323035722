import React, { Fragment } from 'react';
import HeaderBottom from '../../../components/HeaderBottom';
import Breadcumb from '../../../components/Breadcumb';
import FooterArea from '../../../components/FooterArea';
import ContactArea from '../../../components/ContactArea';
import Testmonial from '../../../components/Testmonial';

// images
import breadcumb from '../../../images/breadcumb/work-visa.jpg';
import team from '../../../images/expert/nicolas_lezotte_photoshoot.jpg';

import './style.scss';

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Attorneys', route: '/attorneys' },
    { name: 'Nicolas Lezotte' }
];

const teamDetails = {
    name: 'Nicolas Lezotte, Esq.',
    position: 'Litigation Attorney',
    practiceArea: 'Litigation, Medical Group Operations, Compliance, and Contracts',
    experience: '18+ years',
    office: 'Van Alstyne, Texas, United States',
    phone: '903-820-2977',
    email: '(N/A)',
    education: [
        'Juris Doctorate, Santa Clara University School of Law (2002 - 2005)',
        'Bachelor of Science, Political Science and Government, Santa Clara University (2000 - 2002)',
        'High School Diploma, Bellarmine College Preparatory (1994 - 1998)'
    ],
    summary: `
        Nicolas Lezotte is a seasoned litigation attorney with a proven track record 
        in high-stakes litigation across various fields. Known for securing landmark 
        settlements and defending clients in multi-million-dollar lawsuits, Nicolas has 
        built a reputation for excellence in legal representation. With expertise in contracts, 
        medical group operations, compliance, and government investigations, he combines 
        strategic thinking with a client-first approach.
    `,
    professionalExperience: [
        {
            role: 'Founder and Principal Attorney',
            company: 'Law Offices of Nicolas Lezotte',
            duration: '2007 - Present',
            location: 'DFW Texas',
            description: `
                - Secured the second-largest wrongful death settlement in California in 2022.
                - Defended multi-million-dollar lawsuits featured in major media outlets.
                - Provided expert legal advice as out-of-office General Counsel for small to medium-sized businesses.
                - Expanded operations for a large medical provider from 8 to 58 ambulatory surgery centers across California.
            `
        },
        {
            role: 'Associate Attorney',
            company: 'Law Offices of James Bell, P.C.',
            duration: 'November 2017 - August 2023',
            location: 'Dallas/Fort Worth Area',
            description: `
                - Represented high-net-worth clients in complex litigation.
                - Achieved a multi-million-dollar settlement against a toxicology lab.
                - Played a key role in a high-stakes divorce case involving $500 million in community assets.
            `
        },
        {
            role: 'Associate Attorney – NanoLab Trial',
            company: 'Law Office of Michael Ackerman',
            duration: '2008 - 2009',
            location: 'San Jose, CA',
            description: `
                - Co-counseled in a 4-week trial defending NanoLab Inc. against $50 million in claims.
                - Conducted extensive legal research and witness examination.
            `
        },
        {
            role: 'Intern for Honorable Zoe Lofgren',
            company: 'U.S. House of Representatives',
            duration: '2001',
            location: 'Washington D.C. Metro Area',
            description: `
                - Assisted in drafting Alternative Minimum Tax (AMT) legislation, benefiting over 5,000 workers.
                - Conducted research on tax policy and contributed to legislative discussions.
            `
        }
    ],
    skills: ['Litigation', 'Medical Group Operations', 'Compliance', 'Contracts', 'Government Investigations'],
    linkedin: 'https://www.linkedin.com/company/velie-law-firm-pllc',
    facebook: 'https://www.facebook.com/VelieLawFirm/'
};

const NicolasTeamPage = () => (
    <Fragment>
        <header className="headerArea">
            <HeaderBottom className="headerBottomArea" />
        </header>
        <Breadcumb
            className="breadcumbArea"
            title={`Meet ${teamDetails.name}`}
            breadcumbMenu={breadcumbMenu}
            background={breadcumb}
        />
        <div className="singleTeamArea">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1 col-12">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="singleTeamImgWrap">
                                    <div className="singleTeamImg">
                                        <img src={team} alt={teamDetails.name} />
                                    </div>
                                    <h4>{teamDetails.name}</h4>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="singleTeamContent">
                                    <h4>Important Information</h4>
                                    <ul className="teamMembarInfo">
                                        <li><span>Position: </span>{teamDetails.position}</li>
                                        <li><span>Practice Area: </span>{teamDetails.practiceArea}</li>
                                        <li><span>Experience: </span>{teamDetails.experience}</li>
                                        <li><span>Office: </span>{teamDetails.office}</li>
                                        <li><span>Phone: </span>{teamDetails.phone}</li>
                                    </ul>
                                    <ul className="socialShare">
                                        <li><a href={teamDetails.facebook}><i className="fa fa-facebook"></i></a></li>
                                        <li><a href={teamDetails.linkedin}><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="singleTeamInfo">
                                    <h3>Summary</h3>
                                    <p>{teamDetails.summary}</p>

                                    <h3>Professional Experience</h3>
                                    {teamDetails.professionalExperience.map((job, index) => (
                                        <div key={index}>
                                            <h4>{job.role} - {job.company}</h4>
                                            <p><strong>Duration:</strong> {job.duration}</p>
                                            <p><strong>Location:</strong> {job.location}</p>
                                            <p>{job.description}</p>
                                        </div>
                                    ))}

                                    <h5>Education</h5>
                                    <ul>
                                        {teamDetails.education.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>

                                    <h5>Skills</h5>
                                    <ul>
                                        {teamDetails.skills.map((skill, index) => (
                                            <li key={index}>{skill}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ContactArea className="contactArea" />
        <Testmonial className="testmonialArea" />
        <FooterArea />
    </Fragment>
);

export default NicolasTeamPage;