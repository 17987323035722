import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import Breadcumb from '../../../components/Breadcumb'
import FooterArea from '../../../components/FooterArea'
import ContactArea from '../../../components/ContactArea'
import Testmonial from '../../../components/Testmonial'

// images
import breadcumb from '../../../images/breadcumb/work-visa.jpg'
import team from '../../../images/expert/liz_wellborn_photoshoot.jpg'

import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Attorneys', route: '/attorneys' },
    { name: 'Liz Wellborn' }
]

const teamDetails = {
    name: 'Liz Wellborn',
    position: 'Senior Attorney & Business Executive',
    practiceArea: 'Commercial Litigation, Civil Litigation, Foreclosures, Bankruptcy, Real Estate Law',
    experience: '25+ years',
    offices: 'Dallas-Fort Worth Metroplex',
    phone: '(405) 310-4333',
    email: 'information@velielaw.com',
    memberships: [
        'American Bar Association - Litigation and Real Property Sections',
        'Federal Bar Association',
        'Florida Association of Women Lawyers (FAWL)',
        'State Bar of Florida',
        'State Bar of Georgia',
        'New York State Bar Association',
        'State Bar of Texas',
        'District of Columbia Bar Association',
        'United States District Court-Southern District of Florida',
        'United States District Court- Middle District of Florida',
        'United States District Court- Northern District of Florida',
        'United States Court of Appeals for the Eleventh Circuit'
    ],
    education: [
        'Juris Doctor, Emory University School of Law (1989-1992)',
        'Bachelor of Arts, American Government, Cornell University (1984-1988)',
        'Saint Francis Preparatory High School (1979-1983)'
    ]
};

const SingleTeamPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderBotton className="headerBottomArea" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title={`Meet ${teamDetails.name}`}
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <div className='singleTeamArea'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-12">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamImgWrap">
                                        <div className="singleTeamImg">
                                            <img src={team} alt={teamDetails.name} />
                                        </div>
                                        <h4>{teamDetails.name}</h4>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamContent">
                                        <h4>Important Information</h4>
                                        <ul className="teamMembarInfo">
                                            <li><span>Position: </span>{teamDetails.position}</li>
                                            <li><span>Practice Area: </span>{teamDetails.practiceArea}</li>
                                            <li><span>Experience: </span>{teamDetails.experience}</li>
                                            <li><span>Offices: </span>{teamDetails.offices}</li>
                                            <li><span>Phone: </span>{teamDetails.phone}</li>
                                            <li><span>Email: </span>{teamDetails.email}</li>
                                        </ul>
                                        <ul className="socialShare">
                                            <li><a href="https://www.facebook.com/VelieLawFirm/"><i className="fa fa-facebook"></i></a></li>
                                            <li><a href="https://www.linkedin.com/company/velie-law-firm-pllc"><i className="fa fa-linkedin"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="singleTeamInfo">
                                        <h3>Professional Experience</h3>
                                        <p>Liz has nearly two decades of experience in law related to creditor issues including mortgage, bankruptcy, collection, and REO, among others. She has extensive experience as a litigator in both commercial and civil actions at the state and federal level and is recognized as an expert in protecting the rights of clients involved in legal disputes arising from creditor issues. A graduate of Cornell University and Emory University School of Law in Atlanta, Liz founded ERW Law in 2003 with a focus on managing litigated matters for the mortgage banking community.</p>
                                        
                                        <h5>Previous Positions</h5>
                                        <ul>
                                            <li>Founder at Oak Ridge Title, Inc. (December 2006 - April 2016)</li>
                                            <li>Founder & Principal at ERW Law (June 2003 - April 2016)</li>
                                        </ul>

                                        <h5>Key Achievements</h5>
                                        <ul>
                                            <li>Founded the largest 100% women-owned law firm in the default servicing industry in the U.S.</li>
                                            <li>Established and led a successful title company specializing in closing and REO settlement services</li>
                                            <li>Developed expertise in protecting client rights in creditor-related legal disputes</li>
                                        </ul>

                                        <h5>Bar Memberships & Court Admissions</h5>
                                        <ul>
                                            {teamDetails.memberships.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>

                                        <h5>Education</h5>
                                        <ul>
                                            {teamDetails.education.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        
                                        <h5>Language</h5>
                                        <span>English (fluent)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactArea className="contactArea" />
            <Testmonial className="testmonialArea" />
            <FooterArea />
        </Fragment>
    )
}
export default SingleTeamPage
